/**
 * Transform productSelection data from the cms so that it is easier to use in the front-end.
 * The products can be selected manualy, by category or by tags.
 * @param {object} productSelection - Data about selected products straight from the cms.
 * @param {object} - Transformed link data.
 */

export default productSelection => {
    // Just return false if we don't have any data
    if (!productSelection) {
        return undefined;
    }

    const selectionType = productSelection?.[0].layout;

    // How should Algolia get the correct products? By id? By category? etc.
    let type = undefined;

    // Parameters that Algolia will use to get the correct products. Products ids, category ids etc.
    let values = undefined;

    switch (selectionType) {
        case 'manual': {
            // Return a list of products ids as a array.
            type = 'id';
            values = productSelection?.[0]?.products || [];
            break;
        }
        case 'category': {
            // Return a category id inside a array.
            type = 'category';
            const catID = productSelection?.[0]?.Category || {};
            values = catID ? [catID] : [];
            break;
        }
        case 'tags': {
            // Return a array of tags/keywords.
            type = 'tag';
            const tags = productSelection?.[0]?.tags || [];
            values = tags.map(tag => (tag.tag ? tag.tag : null)).filter(tag => tag);
            break;
        }
        default: {
            break;
        }
    }

    return {
        type,
        values,
    };
};
