import ProductsContainer from 'containers/ProductsContainer';
import ProductsPromotion from 'components/contentLayouts/ProductsPromotion';
import PropTypes from 'prop-types';
import React from 'react';
import activeSetting from 'libs/wordpress/utils/activeSetting';
import colors from 'config/theme/colors';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import parseProductSelection from 'libs/wordpress/utils/transformProductSelection';
import { productSelectionProp } from 'utils/proptypes/modules/productProps';
import { sortArrayByTargetArray } from 'utils/array';
import transformButtons from 'libs/wordpress/utils/transformButtons';
import { transformProductCard } from 'utils/dataTransformers/product';
import useSaveResponse from 'hooks/useSaveResponse';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Pure CMS data transformation before we send it to the "real" block!
const ProductsPromotionBlock = ({ content = {}, moduleTextColor = '', productsQuantity = 6 }) => {
    const { heading, products_selection: productsSelection, settings, buttons } = content;

    // Transform settings
    const hideHeading = activeSetting(settings, 'hide_heading');
    const hideButtons = activeSetting(settings, 'hide_buttons');

    // Transform color data
    const lightTheme = moduleTextColor === 'white';
    const textColor = lightTheme ? colors.white : colors.black;
    const buttonTheme = lightTheme ? 'outlinedWhite' : 'outlinedBlack';

    // Translation and market id
    const { t } = useTranslation();
    const marketId = useSelector(state => state.application.shop_config.market_id);

    // Get productSelection data
    const { type, values } = parseProductSelection(productsSelection);

    // Save response from container
    const [useResponse, saveResponse] = useSaveResponse();

    return (
        <ProductsContainer
            pageSize={productsQuantity}
            ids={type === 'id' ? values : []}
            categories={type === 'category' ? values : []}
            render={({ response }) => {
                saveResponse(response);
                const responseProducts = useResponse?.current?.products || [];

                const transformedProducts = responseProducts?.map(product =>
                    transformProductCard(product, marketId, t)
                );

                let products = transformedProducts;

                // Sort the products from Algolia in the same order as it was from the cms
                if (type === 'id') {
                    const productsSelectionIDs = values?.map(i => parseInt(i, 10)) || [];

                    const sortedProductsSelection = productsSelectionIDs
                        ? sortArrayByTargetArray(
                              productsSelectionIDs,
                              transformedProducts.filter(p => productsSelectionIDs.includes(p.id)),
                              'id'
                          )
                        : [];

                    products = sortedProductsSelection;
                }

                return (
                    <ProductsPromotion
                        buttons={!hideButtons && buttons ? transformButtons(buttons, { theme: buttonTheme }) : null}
                        heading={(!hideHeading && decodeHTMLEntities(heading?.text)) || null}
                        headingTag={(!hideHeading && heading?.type) || null}
                        products={products}
                        textColor={textColor}
                    />
                );
            }}
        />
    );
};

ProductsPromotionBlock.propTypes = {
    content: PropTypes.shape({
        buttons: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]),
        heading: PropTypes.object,
        'products_selection': productSelectionProp.isRequired,
        settings: PropTypes.arrayOf(PropTypes.string),
    }),
    moduleTextColor: PropTypes.string,
    productsQuantity: PropTypes.number,
};

export default ProductsPromotionBlock;
